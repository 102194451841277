.goButton {
  position: relative;
  z-index: 15;
  width: calc(13 * var(--dvh));
  height: calc(13 * var(--dvh));
}

.goButton.small {
  width: calc(11.5 * var(--dvh));
  height: calc(11.5 * var(--dvh));
  font-size: calc(1rem + 0.1 * var(--dvh));
  line-height: 1.4rem;
}

.goButtonSvg {
  display: block;
  width: 100%;
  height: auto;
  color: #f0ecd4;
  filter: drop-shadow(0 8px 8px rgba(0, 0, 0, 0.2));
  transition: filter 180ms ease 100ms, color 700ms ease 500ms,
    transform 200ms ease 100ms;
  isolation: isolate;
  contain: layout paint;
}

.goButtonLabel {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  transition: transform 150ms ease;
  isolation: isolate;
  contain: layout paint;
}

.goButton:active .goButtonSvg {
  filter: brightness(0.985) drop-shadow(0 8px 8px rgba(0, 0, 0, 0.2));
  transform: scale(0.85) translate3d(0, 1.5%, 0);
}

.goButton:active .goButtonLabel {
  transform: scale(0.94) translate3d(0, 3.5%, 0);
}
