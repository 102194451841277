.wrapper {
  position: relative;
  width: 20rem;
  height: 10px;
  isolation: isolate;
}

.wrapper.isDarkMode {
  filter: drop-shadow(0px 1px 1.2px rgba(0, 0, 0, 0.2))
    drop-shadow(0px 1px 4.5px rgba(0, 0, 0, 0.7));
}

.bar {
  position: absolute;
  z-index: 0;
  top: calc(50% - 1px);
  left: 5px;
  right: 5px;
  height: 2px;
  opacity: 0.4;
}

.stages {
  position: absolute;
  z-index: 1;
  top: 0;
  bottom: 0;
  right: 5px;
  left: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.stage {
  position: relative;
  height: 4px;
  width: 4px;
  border-radius: 50%;
}

.progress {
  position: absolute;
  z-index: 2;
  top: 0;
  bottom: 0;
  right: 5px;
  left: 5px;
}

.progressIndicator {
  border-radius: 50%;
  height: 10px;
  width: 10px;
  transform: translateX(-5px);
  transform-origin: center;
}
