.wrapper {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100vw;
  height: calc(100 * var(--dvh));
  overflow: hidden;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: #8ec7e5;
}

.navigationWrapper {
  align-items: center;
  position: fixed;
  left: 0;
  bottom: 0;
  right: 0;
  padding: var(--layout-gutter);
  padding-bottom: calc(2 * var(--layout-gutter));
  display: flex;
  justify-content: space-around;
  z-index: 160;
  display: flex;
  flex-direction: column;
  gap: calc(4 * var(--dvh));
}

.playAgainButton {
  animation: play-again-button-in 1200ms ease 4000ms both;
  transform: scale(1.25);
}

@keyframes play-again-button-in {
  0% {
    opacity: 0;
    transform: scale(1.25) translate3d(0, 20%, 0);
  }
  100% {
    opacity: 1;
    transform: scale(1.25) translate3d(0, 0, 0);
  }
}

.navigationWrapper a {
  text-decoration: underline;
  text-underline-offset: 0.28rem;
  text-decoration-thickness: 0.1rem;
  animation: navigation-wrapper-link-in 1200ms ease 4600ms both;
}

@keyframes navigation-wrapper-link-in {
  0% {
    opacity: 0;
    transform: translate3d(0, 20%, 0);
  }
  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.inner {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  width: 100vw;
}

.title {
  position: relative;
  z-index: 160;
  font-size: calc(11px + 0.6 * var(--dvh));
  pointer-events: none;
  animation: title-in 1300ms ease 3500ms both;
}

@keyframes title-in {
  0% {
    opacity: 0;
    transform: translate3d(0%, 50%, 0);
  }
  100% {
    opacity: 1;
    transform: translate3d(0%, -3%, 0);
  }
}

.instruction {
  position: fixed;
  z-index: 160;
  bottom: calc(15.5 * var(--dvh));
  font-size: calc(11px + 0.6 * var(--dvh));
  pointer-events: none;
  animation: instruction-in 800ms ease 4600ms both;
}

@keyframes instruction-in {
  0% {
    opacity: 0;
    transform: translate3d(0%, 50%, 0);
  }
  100% {
    opacity: 1;
    transform: translate3d(0%, 0, 0);
  }
}

.background {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate3d(-50%, 0, 0);
  width: 100%;
  height: 100%;
  max-width: none;
  touch-action: none;
  pointer-events: none;
  z-index: 0;
  object-fit: cover;
  opacity: 0;
  animation: background-in 2600ms ease 2000ms both;
}

@keyframes background-in {
  0% {
    opacity: 0;
    transform: translate3d(-50%, 20%, 0);
  }
  100% {
    opacity: 1;
    transform: translate3d(-50%, 0, 0) scale(1.1);
  }
}

.foreground {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate3d(-50%, calc(58% - 21vh), 0);
  width: 100%;
  max-width: none;
  touch-action: none;
  pointer-events: none;
  z-index: 150;
  opacity: 0;
  animation: foreground-in 1500ms ease 3200ms both;
}

@keyframes foreground-in {
  0% {
    opacity: 0;
    transform: translate3d(-50%, calc(58% - 21vh), 0);
  }
  100% {
    opacity: 1;
    transform: translate3d(-50%, calc(28% - 21vh), 0) scale(1.2);
  }
}

.carouselContainer {
  width: 100%;
  height: 100%;
  opacity: 0;
  animation: carousel-in 2100ms ease 2400ms both;
}

@keyframes carousel-in {
  0% {
    opacity: 0;
    transform: translate3d(0%, 15%, 0) scale(0.5);
  }
  100% {
    opacity: 1;
    transform: translate3d(0%, 0, 0);
  }
}
