.itemsContainer {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  touch-action: none;
  -webkit-user-select: none;
  user-select: none;
  touch-action: none;
  -webkit-user-select: none;
  user-select: none;
  cursor: grab;
  width: 100%;
  height: 100%;
  background: #f2f2f2;
  transition: opacity 500ms ease, transform 3000ms ease 100ms;
}

.itemsContainer.showChoice {
  transform: scale(1.3) translate3d(-3%, 2%, 0);
}

.itemsContainer:active {
  cursor: grabbing;
}

.backgroundImage {
  height: 100%;
  position: absolute;
  touch-action: none;
  pointer-events: none;
  top: 0;
  right: 0;
  max-width: none;
}

.selectOverlay {
  position: absolute;
  width: calc(32 * var(--dvh));
  max-width: none;
  z-index: 10;
  left: calc(50% + 2.2 * var(--dvh));
  top: calc(50% - 1.9 * var(--dvh));
  pointer-events: none;
  mix-blend-mode: screen;
  mix-blend-mode: plus-lighter;
  transform-origin: 50% 50%;
  opacity: 0;
  transform: translate3d(-50%, -50%, 0) scale(1.7);
  transition: transform 600ms ease 300ms, opacity 900ms ease-in 300ms;
  isolation: isolate;
  contain: layout paint;
}

.showChoice .selectOverlay {
  transform: translate3d(-50%, -50%, 0) scale(1.8);
  opacity: 0.8;
}

.item {
  position: absolute;
  width: calc(24 * var(--dvh));
  max-width: none;
  left: calc(50% - 12 * var(--dvh));
  top: calc(50% - 6 * var(--dvh));
  pointer-events: none;
  object-fit: contain;
  transform: translate3d(0, 0, 0);
  transform-origin: 50% 50%;
  isolation: isolate;
  contain: layout paint;
  will-change: filter;
}

.instructionText {
  position: absolute;
  top: calc(60.2 * var(--dvh));
  display: block;
  width: 80vw;
  left: 50.6vw;
  font-size: calc(11px + 0.6 * var(--dvh));
  text-align: center;
  transform: translate3d(-50%, 0, 0);
  line-height: 1.15;
  letter-spacing: -0.1px;
  z-index: 20;
  color: #fff;
  pointer-events: none;
  opacity: 0;
  isolation: isolate;
  contain: layout;
  text-shadow: 0px 1px 5px rgb(0 0 0 / 70%);
  transition: opacity 500ms ease 1000ms, transform 500ms ease 1000ms;
}

.itemsContainer.hasInteracted:not(.showChoice) .instructionText {
  opacity: 1;
  transform: translate3d(-50%, 0, 0);
}

.itemsContainer.hasInteracted.pointerDown .instructionText {
  opacity: 0;
  transform: translate3d(-50%, 80%, 0);
  transition: opacity 300ms ease 300ms, transform 300ms ease 300ms;
}
