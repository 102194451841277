.wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  display: grid;
  grid-template-areas: "cell";
  justify-items: end;
  align-items: end;
}

.wrapper > * {
  grid-area: cell;
}

.sophie {
  pointer-events: none;
  align-self: end;
  justify-content: end;
  z-index: 1;
}
