.wrapper {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 1;
  background-color: black;
}

.fader {
  --circumference: 2000px;
  --alpha: 0;
  --color-r: 253;
  --color-g: 252;
  --color-b: 240;
  --color: rgb(var(--color-r), var(--color-g), var(--color-b));
  --color-outer: rgba(
    var(--color-r),
    var(--color-g),
    var(--color-b),
    var(--alpha)
  );
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
  background: radial-gradient(
      var(--circumference) at center,
      transparent,
      var(--color)
    ),
    var(--color-outer);
  z-index: 1;
  touch-action: none;
  pointer-events: none;
  /* transform: translate3d(0, 0, 0); */
  will-change: background;
  isolation: isolate;
  contain: layout paint;
}
