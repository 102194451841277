.wrapper {
  height: 100vh;
  height: calc(100 * var(--dvh));
}

.background {
  height: 100%;
  max-width: unset;
  position: absolute;
  touch-action: none;
  pointer-events: none;
  top: 0;
  left: 50%;
  right: 0;
  transform: translate3d(-50%, 0, 0);
}

.quoteWrapper {
  position: fixed;
  z-index: 10;
  width: 100vw;
  height: 100vh;
  opacity: 1;
  pointer-events: none;
}

.instructionsWrapper {
  position: fixed;
  z-index: 10;
  width: 100vw;
  height: 100vh;
  opacity: 0;
  pointer-events: none;
}

.scrollWrapper {
  position: fixed;
  height: 100vh;
  height: calc(100 * var(--dvh));
  width: 100vw;
  transform: translate3d(0, 0, 0);
  transition: transform 1900ms ease-in-out 100ms;
}

.sceneWrapper {
  position: relative;
  height: 100vh;
  height: calc(100 * var(--dvh));
  width: 100vw;
  transform: translate3d(0, 0, 0);
}
