@import "./variables.css";
@import "./typography.css";
@import "./utility.css";
@import "./splitting.css";
@import "./japanese.css";

*,
::before,
::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  -webkit-tap-highlight-color: transparent;
  -webkit-user-select: none;
  -webkit-touch-callout: none;
  user-select: none;
  touch-action: none;
}

html {
  font-size: 62.5%;
}

html,
body {
  height: 100%;
  min-height: 100vh;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  font-family: "Loewe";
  font-size: 1.6rem;
  color: var(--colour-black);
}

*:lang(ja) {
  font-family: tbudmincho-std, sans-serif;
  font-weight: 500;
  font-style: normal;
  letter-spacing: 0;
}

*:lang(zh) {
  font-family: "Noto Sans SC", sans-serif;
  font-weight: 400;
  font-style: normal;
}

*:lang(zh-tw) {
  font-family: "Loewe";
}

a {
  color: inherit;
  text-decoration: none;
  cursor: pointer;
}

li {
  list-style: none;
  font-size: inherit;
}

button {
  font-family: inherit;
  font-size: inherit;
  color: inherit;
  background-color: transparent;
  border: none;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}

img,
picture,
svg {
  display: block;
  max-width: 100%;
  -webkit-user-drag: none;
}

h1,
h2,
h3,
h4 {
  font-weight: 400;
}

:where(a, button, input, select, textarea, [role="button"]):not(:focus-visible) {
  outline: none;
}
