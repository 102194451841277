@font-face {
  font-family: "Loewe";
  src: url("../assets/fonts/Loewe-Regular.woff2") format("woff2"),
    url("../assets/fonts/Loewe-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

.heading-one {
  font-size: 4.8rem;
  line-height: 4.8rem;
  letter-spacing: -0.1rem;
}

.heading-two {
  font-size: 2.6rem;
  line-height: 2.8rem;
  letter-spacing: -0.05rem;
}

.heading-three {
  font-size: 1.8rem;
  line-height: 2rem;
}

.heading-four {
  font-size: 1.5rem;
  line-height: 1.8rem;
}

.heading-five {
  font-size: 2rem;
  line-height: 2.5rem;
  font-weight: normal;
}

.body-large {
  font-size: 1.2rem;
  line-height: 1.4rem;
}

.caps-medium {
  font-size: 1.3rem;
  line-height: 1.6rem;
  letter-spacing: 0.1rem;
  text-transform: uppercase;
}

.caps-small {
  font-size: 1rem;
  line-height: 1.3rem;
  letter-spacing: 0.06rem;
  text-transform: uppercase;
}

.cta {
  font-size: 1.3rem;
  line-height: 1.5rem;
  letter-spacing: -0.02rem;
}

@media screen and (min-width: 640px) {
  .heading-two {
    font-size: 4.4rem;
    line-height: 4.6rem;
  }
  .body-large {
    font-size: 2.2rem;
    line-height: 2.4rem;
  }
}
