.wrapper {
  background-color: #8dbdfa;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  touch-action: none;
}

.copyWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: fixed;
  bottom: 17vh;
  text-align: center;
  width: 100vw;
  padding-inline: var(--layout-padding);
}

.navigationWrapper {
  position: fixed;
  left: 0;
  bottom: 0;
  right: 0;
  padding: var(--layout-gutter) var(--layout-gutter) 3rem;
  display: flex;
  justify-content: space-around;
}

.navigationWrapper button {
  opacity: 0;
  scale: 0.7;
  visibility: hidden;
}

.title {
  color: var(--colour-black-light);
  font-weight: 400;
  opacity: 0;
}

.body {
  opacity: 0;
  padding: 1.5rem 0;
}

.object {
  max-width: 100%;
  height: auto;
  position: absolute;
}

.characters {
  left: 50%;
  bottom: -12vh;
  scale: 1.6;
  transform: translateX(-50%);
  transform-origin: left -12vh;
  visibility: hidden;
}

.cloudLeft {
  left: -20vw;
  bottom: 12vh;
  opacity: 0;
  transform: translateX(-20vw);
  transform-origin: -20vw -12vh;
}

.cloudRight {
  bottom: 0vh;
  filter: blur(3px);
  opacity: 0;
  transform: translateX(5vw);
  transform-origin: 5vw 0vh;
  right: -5vw;
}

.cloudBottom {
  /* filter: blur(3px); */
  width: 100vw;
}

.cloudBottomFront {
  bottom: -20vh;
  transform: scale(3);
}

.cloudBottomBack {
  bottom: 3vh;
  opacity: 0;
  transform: scale(3);
}

.door {
  left: 50%;
  opacity: 0;
  top: 40vh;
  transform: translateX(-50%);
  transform-origin: center -40vh;
}

.doorFill {
  left: 50%;
  opacity: 0;
  top: 29vh;
  transform: translate(-50%, -29vh) scale(1.6, 1.6);
  transform-origin: center -29vh;
}

.copyright {
  position: absolute;
  bottom: 30vh;
  left: 1rem;
  font-size: 1.2rem;
  color: #15406c;
  pointer-events: none;
  writing-mode: vertical-rl;
  opacity: 0;
  rotate: 180deg;
}

.copyright span {
  display: inline-block;
  transform: rotate(90deg);
}
