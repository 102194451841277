.stage {
  position: fixed;
  display: flex;
  align-items: center;
  bottom: 0;
  left: 0;
  right: 0;
  padding: var(--layout-gutter);
  transform: translate3d(0, calc(-1 * var(--layout-gutter)), 0);
  transition: opacity 1s ease-in-out, transform 2000ms ease 100ms;
  /* Inactive styles */
  opacity: 0;
  z-index: 0;
  pointer-events: none;
  visibility: hidden;
}

.stage.showButtons {
  transform: translate3d(0, calc(-0.6 * var(--layout-gutter)), 0);
}

.stage.isActive {
  opacity: 1;
  z-index: 1;
  pointer-events: auto;
  visibility: visible;
  animation: stage-in 3000ms ease 6000ms backwards;
}

@keyframes stage-in {
  0% {
    opacity: 0;
    transform: translate3d(0, 0, 0);
  }
  100% {
    opacity: 1;
    transform: translate3d(0, calc(-1 * var(--layout-gutter)), 0);
  }
}

.stage.start {
  justify-content: space-around;
}

.stage.game {
  justify-content: space-between;
}

.stage.end {
  gap: 4rem;
  justify-content: center;
}

.startButton {
  width: 8rem;
  height: 8rem;
}

.gameButton {
  position: relative;
  z-index: 1;
  visibility: hidden;
  opacity: 0;
  transition: opacity 400ms ease 100ms, visibility 400ms ease 100ms;
}

.showButtons .gameButton {
  visibility: visible;
  opacity: 1;
}

.showButtons .hiddenButton {
  visibility: hidden;
  opacity: 0;
}

.gameButton.isDarkMode {
  text-shadow: 0px 1px 5px rgba(0, 0, 0, 0.7);
}

.progressBar {
  position: relative;
  z-index: 0;
}

.endButtonSmall {
  width: 6.4rem;
  height: 6.4rem;
}

.endButtonLarge {
  width: 9rem;
  height: 9rem;
}
