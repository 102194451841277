.wrapper {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100vw;
  height: calc(100 * var(--dvh));
  overflow: hidden;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.logo {
  margin-top: 2rem;
}

.collection,
.game {
  position: fixed;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  align-items: center;
  padding-inline: var(--layout-padding);
}

.collection {
  top: 8rem;
  text-align: center;
}

.game {
  bottom: 10vh;
  text-align: center;
}

@media screen and (min-width: 640px) {
  .collection p {
    font-size: 2.8rem;
    line-height: 3rem;
  }
  button {
    width: 30%;
  }
}
