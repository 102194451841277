.wrapper {
  background-color: rgba(0, 0, 0, 0.85);
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 2rem;
}

.shareImage {
  width: 70%;
}

@media screen and (min-width: 640px) {
  .shareImage {
    width: 50%;
  }
}

.shareImage img {
  pointer-events: all;
  -webkit-touch-callout: default;
  touch-action: default;
}

.instructions,
.close {
  color: var(--colour-cream);
}

.close {
  border-radius: 50%;
  border: solid 1px var(--colour-cream);
  width: 4rem;
  height: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
