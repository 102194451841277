.wrapper {
  align-items: center;
  background-color: var(--colour-cream);
  background-repeat: no-repeat;
  background-position: left bottom;
  background-size: contain;

  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  display: flex;
  flex-direction: column;
  gap: 2rem;

  justify-content: center;
  z-index: 999;
}

.leaves {
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  height: 100vh;
  position: absolute;
  width: 100vw;
}

.rotate {
  height: 30vh;
}

.copy {
  margin-top: 0;
}
