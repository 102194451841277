.itemsContainer {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  touch-action: none;
  -webkit-user-select: none;
  user-select: none;
  cursor: grab;
  width: 100%;
  height: 100%;
  transition: opacity 1000ms ease 500ms, transform 3200ms ease 100ms;
}

.itemsContainer.introCompleted {
  opacity: 1;
}

.itemsContainer.showChoice {
  transform: scale(1.35) translate3d(2.5%, 10%, 0);
}

.itemsContainer:active {
  cursor: grabbing;
}

.backgroundImage {
  height: 100%;
  position: absolute;
  touch-action: none;
  pointer-events: none;
  top: 0;
  left: calc(50%);
  transform: translate(-48%, 0);
  max-width: none;
}

.selectOverlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  mix-blend-mode: overlay;
  opacity: 0;
  transform: scale(3);
  transform-origin: 50% 35%;
  transition: opacity 300ms ease-in 300ms, transform 1650ms ease 1600ms;
  isolation: isolate;
  contain: layout paint;
  touch-action: none;
  -webkit-user-select: none;
  user-select: none;
  pointer-events: none;
}

.showChoice .selectOverlay {
  transform: scale(1);
  opacity: 1;
}

.selectOverlaySparkle {
  position: absolute;
  width: calc(32 * var(--dvh));
  max-width: none;
  z-index: 10;
  left: calc(50% - 1 * var(--dvh));
  top: calc(50% - 17.9 * var(--dvh));
  pointer-events: none;
  mix-blend-mode: screen;
  mix-blend-mode: plus-lighter;
  transform-origin: 50% 50%;
  opacity: 0;
  transform: translate3d(-50%, -50%, 0) scale(1.2);
  transition: transform 300ms ease 1200ms, opacity 1300ms ease-in 1200ms;
  isolation: isolate;
  contain: layout paint;
}

.showChoice .selectOverlaySparkle {
  transform: translate3d(-50%, -50%, 0) scale(1.8);
  opacity: 1;
}

.item {
  position: absolute;
  z-index: 10;
  width: calc(19 * var(--dvh));
  height: calc(19 * var(--dvh));
  max-width: none;
  left: calc(50% - 8 * var(--dvh));
  top: calc(50% - 9.5 * var(--dvh));
  pointer-events: none;
  object-fit: contain;
  transform: translate3d(0, 0, 0);
  isolation: isolate;
  will-change: filter;
}

.item.isSelected {
  animation: item-selected 1200ms 600ms both;
}

@keyframes item-selected {
  0% {
    mix-blend-mode: normal;
  }
  90% {
    opacity: 0.5;
  }
  100% {
    mix-blend-mode: plus-lighter;
    opacity: 1;
  }
}

.itemImage,
.itemShadow {
  position: relative;
  width: 100%;
  height: 100%;
  pointer-events: none;
  object-fit: contain;
  transform: translate3d(0, 0, 0);
  will-change: filter;
  transition: transform 1000ms ease 100ms;
}

.itemImage {
  z-index: 10;
}

.itemShadow {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}

.item.isSelected .itemImage,
.item.isSelected .itemShadow {
  transform: scale(0.8) translate3d(0, calc(2 * var(--dvh)), 0);
}

.calciferMouth,
.calciferMouthOutline {
  position: absolute;
  z-index: 2;
  max-width: none;
  width: calc(25 * var(--dvh));
  height: calc(12.5 * var(--dvh));
  left: calc(50% + -12.5 * var(--dvh));
  top: calc(50% + -20.4 * var(--dvh));
  transform: translate3d(0, 0, 0);
  background-size: contain;
  background-repeat: no-repeat;
  mask-image: url("../../assets/images/feed-calcifer/mouth.webp");
  mask-repeat: no-repeat no-repeat;
  mask-position: center;
}

.calciferMouthOutline {
  background-color: #713926;
  opacity: 0.8;
  mask-size: 100% 85%;
  z-index: 1;
}

.showChoice .calciferMouthOutline {
  mask-image: url("../../assets/images/feed-calcifer/mouth-2.webp");
  transition: mask-size 300ms ease 3000ms, mask-image 1ms ease 3150ms;
  mask-size: 80% 45% !important;
}

.showChoice .calciferMouth {
  mask-image: url("../../assets/images/feed-calcifer/mouth-2.webp");
  transition: mask-size 300ms ease 3000ms, mask-image 1ms ease 3150ms;
  mask-size: 78.5% 42% !important;
}

.showChoice .item.isSelected .itemImage,
.showChoice .item.isSelected .itemShadow {
  opacity: 0;
  transition: opacity 300ms ease 3000ms, transform 300ms ease 3000ms;
}

.pupilLeft,
.pupilRight {
  position: absolute;
  width: calc(2.45 * var(--dvh));
  height: calc(2.45 * var(--dvh));
  border-radius: 50%;
  left: calc(50% - 9 * var(--dvh));
  top: calc(50% - 24.5 * var(--dvh));
  transform: translate3d(0, 0, 0);
  isolation: isolate;
  contain: layout paint;
  background-size: contain;
  background-repeat: no-repeat;
}

.pupilLeft {
  background-image: url("../../assets/images/feed-calcifer/left-pupil.png");
}

.pupilRight {
  background-image: url("../../assets/images/feed-calcifer/right-pupil.png");
  left: calc(50% + 6 * var(--dvh));
  top: calc(50% - 24.6 * var(--dvh));
}

.instructionText {
  position: absolute;
  top: calc(83.5 * var(--dvh));
  display: block;
  width: 80vw;
  left: 50vw;
  font-size: calc(11px + 0.6 * var(--dvh));
  text-align: center;
  transform: translate3d(-50%, 0, 0);
  line-height: 1.15;
  letter-spacing: -0.1px;
  z-index: 20;
  color: #fff;
  pointer-events: none;
  opacity: 0;
  isolation: isolate;
  contain: layout;
  text-shadow: 0px 1px 5px rgb(0 0 0 / 70%);
  transition: opacity 500ms ease 2000ms, transform 500ms ease 2000ms;
}

.itemsContainer.introCompleted.hasInteracted .instructionText {
  opacity: 1;
  transform: translate3d(-50%, 0, 0);
}

.itemsContainer.hasInteracted.pointerDown .instructionText {
  opacity: 0;
  transform: translate3d(-50%, 80%, 0);
  transition: opacity 300ms ease 300ms, transform 300ms ease 300ms;
}
