.wrapper {
  position: absolute;
  top: 0;
  left: 0;
  display: grid;
  width: 100%;
  height: 100%;
}

@supports (height: 1dvh) {
  .wrapper {
    height: 100dvh;
  }
}

canvas {
  display: block;
  width: 100%;
  height: 100%;
}
