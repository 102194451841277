.quoteContainer {
  background: transparent;
  width: 100%;
  display: flex;
  position: absolute;
  background: #f6f4e4;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  touch-action: none;
  -webkit-user-select: none;
  user-select: none;
}

.quoteContainer .quote {
  background-color: transparent;
  pointer-events: none;
  position: static;
  width: 100%;
  max-width: calc(48 * var(--dvh));
  margin: calc(5 * var(--dvh)) auto 0 auto;
  animation: quote-in-out 4200ms ease 500ms both;
}

@keyframes quote-in-out {
  0% {
    transform: translate3d(0, 10%, 0);
    opacity: 0;
  }
  79% {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
  83% {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
  100% {
    transform: translate3d(0, 0, 0);
    opacity: 0;
  }
}

.quoteContainer .quote h2,
.quoteContainer .quote h3 {
  color: #000;
}

.doorDialContainer {
  --show-choice-delay: 300ms;

  display: flex;
  position: absolute;
  background: #f6f4e4;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  touch-action: none;
  -webkit-user-select: none;
  user-select: none;
  transition: background-color 700ms ease 500ms;
  /* prevent interaction (until after intro) */
  pointer-events: none;
}

.doorDialContainer.choice-none {
  background-color: #f6f4e4;
}

.doorDialContainer.choice-the-waste {
  background-color: #a26b8d;
}

.doorDialContainer.choice-market-chipping {
  background-color: #ff8080;
}

.doorDialContainer.choice-kingsbury {
  background-color: #93c0fa;
}

.doorDialContainer.choice-howls-garden {
  background-color: #d5fab8;
}

.arc {
  position: absolute;
  display: flex;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  touch-action: none;
  -webkit-user-select: none;
  user-select: none;
  transition: background-color 700ms ease 500ms;
}

.doorBackground {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  mask-image: url("../../assets/images/door-dial/door-mask.png");
  mask-size: min(100%, calc(56 * var(--dvh)));
  mask-repeat: no-repeat no-repeat;
  mask-position: center;
  transition: background-color 700ms ease 500ms;
  background-color: #fdfcf0;
}

.choice-none .doorBackground {
  background-color: #fdfcf0;
}

.choice-the-waste .doorBackground {
  background-color: #5a3e4f;
}

.choice-market-chipping .doorBackground {
  background-color: #fd5d6c;
}

.choice-kingsbury .doorBackground {
  background-color: #6ba0e4;
}

.choice-howls-garden .doorBackground {
  background-color: #a5db7b;
}

.doorDial {
  position: relative;
  width: 80vw;
  height: calc(100 * var(--dvh));
  max-width: calc(31.5 * var(--dvh));
  top: calc(50 * var(--dvh));
  bottom: calc(50 * var(--dvh));
  left: calc(50vw);
  transform: translate(-49%, -17.5%);
  transform-origin: 50% 15%;
  touch-action: none;
  -webkit-user-select: none;
  user-select: none;
  cursor: grab;
  animation: door-dial-in 1800ms 10ms ease backwards;
}

@keyframes door-dial-in {
  0% {
    transform: translate(-49%, -17.5%) scale(0.9);
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.doorDial:active {
  cursor: grabbing;
}

.doorDialImage {
  width: 100%;
  touch-action: none;
  pointer-events: none;
  transform-origin: 49% 50.8%;
}

.doorDialDropShadow {
  position: absolute;
  z-index: 0;
  width: 100%;
  touch-action: none;
  pointer-events: none;
  transform-origin: 49% 50.8%;
  transform: scale(0.985) translate3d(-1%, 0, 0);
  filter: grayscale() contrast(0) brightness(1)
    drop-shadow(-0.65vh 1.8vh 0.37vh rgba(0, 0, 0, 0.18));
}

.isSafari .doorDialDropShadow {
  filter: grayscale() contrast(0) brightness(1)
    drop-shadow(-0.75vh 1.67vh 0.39vh rgba(0, 0, 0, 0.18));
}

.doorDialArrowShadow {
  position: absolute;
  z-index: 1;
  width: 100%;
  touch-action: none;
  pointer-events: none;
  transform-origin: 49% 50.8%;
  transform: translate3d(1.3%, -0.8%, 0);
  isolation: isolate;
  contain: layout paint;
}

.doorDialImageShadow {
  position: absolute;
  z-index: 1;
  width: 100%;
  touch-action: none;
  pointer-events: none;
  transform-origin: 49% 50.8%;
  transform: translate3d(0, 0, 0);
  isolation: isolate;
  contain: layout paint;
}

.doorDialImageSpecular {
  position: absolute;
  z-index: 2;
  width: 100%;
  touch-action: none;
  pointer-events: none;
  transform: translate3d(0, 0, 0) rotate(1.58rad);
  transform-origin: 49% 50.8%;
  isolation: isolate;
  contain: layout paint;
}

.doorDialImageArrow {
  position: absolute;
  z-index: 3;
  width: 100%;
  touch-action: none;
  pointer-events: none;
  transform-origin: 49% 50.8%;
  transform: translate3d(0, 0, 0);
  isolation: isolate;
  contain: layout paint;
}

.doorDialImageArrowBehind {
  position: absolute;
  z-index: 0;
  width: 100%;
  touch-action: none;
  pointer-events: none;
  transform-origin: 49% 50.8%;
  transform: translate3d(0, 0, 0);
  isolation: isolate;
  contain: layout paint;
}

.doorDialText {
  position: absolute;
  top: calc(69.2 * var(--dvh));
  display: block;
  width: 80vw;
  left: 50vw;
  font-size: calc(11px + 2.25 * var(--dvh));
  text-align: center;
  transform: translate3d(-50%, calc(6.8 * var(--dvh)), 0);
  line-height: 1.15;
  letter-spacing: -0.5px;
  z-index: 20;
  transition: color 200ms ease 420ms;
  animation: text-in 660ms ease 820ms backwards;
  isolation: isolate;
  contain: layout;
}

.hasInteracted .doorDialText {
  opacity: 1;
  transform: translate3d(-50%, 0, 0);
  animation: none;
  transition: color 200ms ease 420ms, transform 950ms ease 680ms;
}

.showChoice .doorDialText {
  transition: color 300ms ease calc(var(--show-choice-delay) + 100ms),
    opacity 400ms ease calc(var(--show-choice-delay) + 1250ms),
    transform 500ms ease calc(var(--show-choice-delay) + 1250ms);
  color: #fff;
  animation: none;
  transform: translate3d(-50%, 45%, 0);
  opacity: 0;
}

.choice-the-waste .doorDialText {
  transition: color 300ms ease calc(var(--show-choice-delay) + 100ms),
    transform 800ms ease calc(var(--show-choice-delay) + 200ms);
  color: #fff;
}

@keyframes text-in {
  0% {
    transform: translate3d(-50%, calc(6.8 * var(--dvh) + 16%), 0);
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.instructionText {
  position: absolute;
  top: calc(58 * var(--dvh));
  display: block;
  width: 80vw;
  left: 49.8vw;
  font-size: calc(11px + 0.45 * var(--dvh));
  text-align: center;
  transform: translate3d(-50%, 0, 0);
  line-height: 1.15;
  letter-spacing: -0.1px;
  z-index: 20;
  color: #fff;
  pointer-events: none;
  opacity: 0;
  isolation: isolate;
  contain: layout;
  text-shadow: 0px 1px 5px rgb(0 0 0 / 70%);
  transition: opacity 400ms ease 500ms, transform 400ms ease 500ms;
  animation: instruction-text-in 650ms ease 2900ms backwards;
}

.choice-none:not(.hasInteracted) .instructionText {
  opacity: 1;
  transform: translate3d(-50%, 0, 0);
}

@keyframes instruction-text-in {
  0% {
    transform: translate3d(-50%, 16%, 0);
    opacity: 0;
  }
  100% {
    transform: translate3d(-50%, 0, 0);
    opacity: 1;
  }
}

.cursorContainer {
  position: absolute;
  display: block;
  z-index: 30;
  left: calc(50vw - 7.5 * var(--dvh));
  top: calc(53.7 * var(--dvh));
  width: calc(12 * var(--dvh));
  pointer-events: none;
  animation: cursor-in 700ms ease 2000ms backwards;
  transition: opacity 400ms ease 500ms, transform 400ms ease 500ms;
}

.hasInteracted .cursorContainer {
  opacity: 0;
  transform: translate3d(0, 26%, 0);
}

@keyframes cursor-in {
  0% {
    opacity: 0;
    transform: translate3d(20%, 40%, 0) rotate(-15deg);
  }
  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.cursor {
  display: block;
  width: 100%;
  transition: opacity 400ms ease 500ms, transform 400ms ease 500ms;
  animation: cursor-flick 1800ms cubic-bezier(0.72, 0.01, 0, 1.72) 1700ms both;
  animation-direction: alternate-reverse;
  animation-iteration-count: infinite;
  opacity: 1;
}

@keyframes cursor-flick {
  0% {
    transform: translate3d(-65%, 5%, 0) rotate(4deg) scale(1.09);
    filter: drop-shadow(1px calc(2 * var(--dvh)) 5px rgba(0, 0, 0, 0.12));
  }
  70% {
    transform: translate3d(-10%, 26%, 0) rotate(-18deg) scale(1, 0.94);
    filter: drop-shadow(-4px calc(1.2 * var(--dvh)) 3px rgba(0, 0, 0, 0.12));
  }
  100% {
    transform: translate3d(-10%, 26%, 0) rotate(-18deg) scale(1, 0.94);
    filter: drop-shadow(-4px calc(1.2 * var(--dvh)) 3px rgba(0, 0, 0, 0.12));
  }
}

.navigationWrapper {
  position: absolute;
  z-index: 20;
  bottom: calc(10.5 * var(--dvh));
  left: 50vw;
  transform: translate3d(-50%, 16%, 0);
  opacity: 0;
  transition: transform 300ms ease 1800ms, opacity 300ms ease 1800ms;
}

.hasInteracted .navigationWrapper {
  transform: translate3d(-50%, 0, 0);
  opacity: 1;
}

.showChoice .navigationWrapper {
  pointer-events: none;
  opacity: 0;
  transform: translate3d(-50%, 16%, 0) scale(0.75);
  transition: transform 300ms ease 100ms, opacity 300ms ease 100ms;
}

.goButton {
  position: relative;
  z-index: 15;
  width: calc(13 * var(--dvh));
  height: calc(13 * var(--dvh));
  cursor: pointer;
  visibility: hidden;
  transition: visibility 100ms ease 1400ms;
}

.hasInteracted .goButton {
  visibility: visible;
}

.choice-none .goButtonSvg {
  color: #f0ecd4;
}

.choice-the-waste .goButtonSvg {
  color: #c681ab;
}

.choice-market-chipping .goButtonSvg {
  color: #ff8080;
}

.choice-kingsbury .goButtonSvg {
  color: #8dbdfa;
}

.choice-howls-garden .goButtonSvg {
  color: #d8ffb8;
}

.windowContainer {
  /* 
    element is sized at full screen then scaled down
    avoids safari bug 27684:
    https://bugs.webkit.org/show_bug.cgi?id=27684
  */
  position: absolute;
  width: calc(100 * var(--dvh));
  height: calc(100 * var(--dvh));
  top: calc(22.3 * var(--dvh));
  left: 50.2%;
  z-index: 1;
  transform: translate3d(-50%, -50%, 0) scale(0.124);
  transition: transform 1000ms ease calc(var(--show-choice-delay) - 100ms);
  animation: window-container-in 1200ms 530ms ease backwards;
  pointer-events: none;
}

@keyframes window-container-in {
  0% {
    transform: translate3d(-50%, -50%, 0) scale(0.097);
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.windowScenes {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: #98c9e3;
  z-index: 1;
  border-radius: 50%;
  isolation: isolate;
  contain: layout paint;
}

.windowGlass {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  width: 114.15%;
  max-width: none;
  height: auto;
  transform: translate3d(-5.7%, -6.7%, 0);
  transition: opacity 400ms ease 300ms;
  isolation: isolate;
  contain: layout paint;
}

.choice-none .windowGlass {
  opacity: 0.65;
}

.showChoice .windowContainer {
  animation: none;
  transform: translate3d(-50%, calc(27.5 * var(--dvh) - 50%), 0) scale(1.44);
}

.showChoice .windowGlass {
  opacity: 0;
  transition: opacity 400ms ease calc(var(--show-choice-delay) + 300ms);
}

.showChoice .doorOverlay {
  opacity: 1;
  transform: translate3d(-50%, 0, 0) scale(1);
}

.doorStroke {
  display: block;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate3d(-50%, -50.2%, 0);
  object-fit: contain;
  width: min(100%, calc(56 * var(--dvh)));
  z-index: 0;
  pointer-events: none;
  opacity: 0;
  transition: opacity 300ms ease 600ms;
  isolation: isolate;
  contain: layout paint;
}

.hasInteracted .doorStroke {
  opacity: 0.6;
}

.doorOverlay {
  display: block;
  position: absolute;
  top: 0;
  left: 50%;
  height: calc(100 * var(--dvh));
  max-width: none;
  width: auto;
  transform: translate3d(-50%, 0, 0) scale(1.2);
  z-index: 10;
  pointer-events: none;
  opacity: 0;
  transform-origin: 50% 90%;
  transition: transform 800ms ease calc(var(--show-choice-delay)),
    opacity 1800ms ease calc(var(--show-choice-delay));
  isolation: isolate;
  contain: layout paint;
}

.windowScene {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 138%;
}

.windowClouds {
  display: block;
  position: absolute;
  z-index: 0;
  top: 50%;
  left: 50%;
  width: 100%;
  animation: window-clouds-in 3600ms 500ms cubic-bezier(0.2, 1.1, 0.4, 1) both;
  pointer-events: none;
  transform: translate3d(-50%, -50%, 0) scale(1.1);
}

@keyframes window-clouds-in {
  0% {
    transform: translate3d(-50%, -40%, 0) scale(2.5);
  }
  100% {
    transform: translate3d(-49%, -45%, 0) scale(1.1);
  }
}

.windowSceneImage {
  display: block;
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  object-fit: contain;
  transform: translate3d(0, 0, 0);
  transition: opacity 600ms ease 300ms,
    transform 5900ms ease calc(var(--show-choice-delay) - 100ms),
    filter 2000ms ease calc(var(--show-choice-delay) + 1500ms);
  isolation: isolate;
  contain: layout paint;
  opacity: 0;
}

.hasInteracted .windowSceneImage {
  opacity: 1;
}

.showChoice .windowSceneImage {
  opacity: 0;
}

.showChoice.choice-the-waste .windowSceneImage-the-waste {
  opacity: 1;
  transform: translate3d(calc(79.5 * var(--dvh) - 50%), 0, 0) scale(0.7);
}

.showChoice.choice-market-chipping .windowSceneImage-market-chipping {
  opacity: 1;
  transform: translate3d(calc(79.5 * var(--dvh) - 50%), 0, 0) scale(0.7);
}

.showChoice.choice-kingsbury .windowSceneImage-kingsbury {
  opacity: 1;
  transform: translate3d(calc(88.4 * var(--dvh) - 50%), 0, 0) scale(0.7);
}

.showChoice.choice-howls-garden .windowSceneImage-howls-garden {
  opacity: 1;
  transform: translate3d(calc(80 * var(--dvh) - 50%), 0, 0) scale(0.7);
}

.textureOverlay {
  position: absolute;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 10;
  object-fit: cover;
  opacity: 0.1;
  mix-blend-mode: difference;
  transition: opacity 300ms ease;
  isolation: isolate;
  contain: layout paint;
}

.doorDialContainer:not(.choice-none) .textureOverlay {
  transition: opacity 300ms ease;
  opacity: 0.138;
}

.doorDialContainer.showChoice:not(.choice-none) .textureOverlay {
  transition: opacity 2000ms ease 1500ms;
  opacity: 0;
}

.sceneOverlay {
  position: absolute;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 10;
  object-fit: cover;
  object-position: 50% 100%;
  opacity: 0;
  transform-origin: 50% 100%;
  transform: translate3d(0, 40%, 0) scale(2);
  transition: transform 1800ms ease 600ms, opacity 600ms ease 600ms;
  isolation: isolate;
  contain: layout paint;
}

.choice-none .sceneOverlay {
  transition: transform 1800ms ease 100ms, opacity 300ms ease 100ms;
}

.doorDialContainer.showChoice .sceneOverlay {
  /* increased specificity used to override */
  opacity: 0;
  transition: transform 1600ms ease 100ms, opacity 200ms ease 400ms;
  transform: translate3d(0, 100%, 0) scale(3);
}

.choice-the-waste .theWasteOverlay {
  opacity: 1;
  transform: scale(1);
}

.choice-market-chipping .marketChippingOverlay {
  opacity: 1;
  transform: scale(1);
}

.choice-kingsbury .kingsburyOverlay {
  opacity: 1;
  transform: scale(1);
}

.choice-howls-garden .howlsGardenOverlay {
  opacity: 1;
  transform: scale(1);
}
