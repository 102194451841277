:root {
  /* Colours */
  --colour-black: #000000;
  --colour-black-light: #202020;
  --colour-white: #ffffff;
  --colour-cream: #f6f4e4;
  --colour-cream-light: #fdfcef;
  --colour-cream-dark: #f0ecd3;
  --colour-brown: #dac2a8;
  --colour-green: #a3d978;
  --colour-green-light: #d8ffb8;
  --colour-green-dark: #4b702d;
  --colour-blue: #689fe4;
  --colour-blue-light: #8dbdfa;
  --colour-blue-dark: #213f68;
  --colour-red: #f35b5b;
  --colour-red-light: #ff8080;
  --colour-red-dark: #682121;

  /* Layout */
  --layout-gutter: 1rem;
  --layout-padding: 2rem;
  --layout-nav-controls-height: 4.2rem;
}
