.colour-black {
  color: var(--colour-black-light);
}

.colour-white {
  color: var(--colour-white);
}

.background-colour-black {
  background-color: var(--colour-black-light);
}

.background-colour-white {
  background-color: var(--colour-white);
}
