.wrapper {
  align-items: center;
  background: rgba(0, 0, 0, 0.3);
  bottom: 0;
  color: var(--colour-white);
  display: flex;
  height: 100vh;
  height: calc(100 * var(--dvh));
  justify-content: center;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
}

.copy {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 0 3rem;
  text-align: center;
}

.action {
  padding: 0 4rem;
}

.image {
  max-width: 16rem;
  width: 100%;
}
