:lang(ja) .heading-two {
  font-size: 2rem;
  line-height: 3rem;
}

:lang(ja) .heading-five {
  line-height: 2.9rem;
}

:lang(ja) .body-large {
  font-size: 1.3rem;
  line-height: 2rem;
}

:lang(ja) button {
  font-size: 1.3rem;
}
