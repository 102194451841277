.controls {
  position: fixed;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: space-between;
  top: 0;
  left: 0;
  right: 0;
  padding: 0 var(--layout-gutter);
  height: var(--layout-nav-controls-height);
}

.externalLink.isDarkMode {
  text-shadow: 0px 1px 5px rgba(0, 0, 0, 0.7);
}

.externalLinkText {
  transition: color 300ms ease 100ms, text-shadow 300ms ease 100ms;
}

.menuWrapper {
  display: flex;
  align-items: center;
}

.muteButton {
  width: 3.5rem;
  height: 3.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.25rem;
}

.muteButton svg {
  width: 1.3rem;
  height: 1.3rem;
  display: block;
}

.menu {
  position: fixed;
  text-align: center;
  top: 0;
  padding: var(--layout-nav-controls-height) 1rem 1rem 1rem;
  min-width: 18rem;
  right: 0;
  z-index: 10;
  background: linear-gradient(153.02deg, #ffffff -2.01%, #ebebeb 96.74%);
  box-shadow: inset 1px -1px 0px rgba(0, 0, 0, 0.09);
  filter: drop-shadow(-10px 14px 4px rgba(0, 0, 0, 0.12));
  border-radius: 10px;
}

.menuButton {
  position: relative;
  overflow: hidden;
  padding: 1rem 0.4rem 1rem 0;
  width: 100%;
  z-index: 11;
}

.menuButtonText {
  display: inline-block;
  min-width: 5rem;
  transition: color 300ms ease 100ms, text-shadow 300ms ease 100ms;
}

.menuButtonText.isDarkMode {
  text-shadow: 0px 1px 5px rgba(0, 0, 0, 0.8);
}

.menuButtonText.isOpen {
  position: absolute;
  top: 0;
  right: 0;
}

.menuList {
  border-top: 1px solid #f0f0f0;
  display: flex;
  flex-direction: column;
}

.menuListItem {
  padding: 2rem 0;
  width: 100%;
}

.menuListItem button {
  width: 100%;
}

.languageSelect {
  position: relative;
  padding: 2rem 0 1rem;
}

.languageSelectBackground {
  position: absolute;
  z-index: -1;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: var(--colour-white);
  border-radius: 10px;
}

.languageSelectButton {
  padding-bottom: 1rem;
  width: 100%;
}

.languageSelectButton > span {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  justify-content: center;
}

.languageSelectIcon {
  position: relative;
  display: inline-block;
}

.languageList {
  border-top: 1px solid #f0f0f0;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  margin: 0 1rem;
}

.languageListItem {
  padding: 1rem 0;
}

.languageListItem.isActive {
  opacity: 0.5;
}
