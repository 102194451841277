.wrapper {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100vw;
  height: calc(100 * var(--dvh));
  overflow: hidden;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.clickTarget {
  height: 100vh;
  position: fixed;
  width: 100vw;
  z-index: 100;
}
.instructions {
  color: var(--colour-cream-dark);
  position: fixed;
  left: 50%;
  text-align: center;
  top: 60%;
  transform: translate(-50%, -60%);
  width: 100%;
  z-index: 1;
  touch-action: none;
  pointer-events: none;
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 0 3rem;
  text-align: center;
}

.cursor {
  width: 20%;
}

.clouds {
  position: absolute;
  bottom: 0;
  pointer-events: none;
  touch-action: none;
  z-index: 3;
  left: 0;
}

.doorParts {
  position: fixed;
  width: 100vw;
  height: 100vh;
  scale: 0.9;
  display: flex;
  justify-content: center;
  transform-origin: bottom;
}

.door,
.doorPanel {
  position: absolute;
  bottom: 0;
}

.door img,
.doorPanel img {
  height: 95vh;
}

.doorPanel {
  --rotate-value: 0deg;
  z-index: 2;
  transform: perspective(1200px) rotate3d(0, 1, 0, var(--rotate-value));
  transform-style: preserve-3d;
  transform-origin: 16%;
  will-change: var(--rotate-value);
}

.logo {
  opacity: 0;
}

.character {
  position: absolute;
  bottom: -20vh;
  opacity: 0;
  transform-origin: bottom;
  left: calc(50%);
  transform: translateX(calc(-50%));
  z-index: 0;
  pointer-events: none;
  touch-action: none;
  max-height: 80%;
}

.isLight {
  color: var(--colour-cream-dark);
}

.isDark {
  color: var(--colour-black);
}

.characterBackground {
  position: fixed;
  height: 100vh;
  width: 100vh;
  object-fit: cover;
  z-index: 0;
  left: 0;
  transform-origin: center;
  scale: 0;
  filter: blur(50px);
  top: 50vh;
  transform: translateY(-50vh);
  transform-origin: top;
  pointer-events: none;
  touch-action: none;
  will-change: scale filter transform;
}

.characterForeground {
  z-index: 2;
  position: absolute;
  bottom: 0;
  scale: 1;
  left: 0;
  opacity: 0;
}

.characterName {
  font-size: 5.8rem;
  line-height: 6rem;
  text-align: center;
  visibility: hidden;
  z-index: 1;
  padding-top: 1rem;
}

.headline {
  z-index: 2;
  padding-inline: var(--layout-padding);
  text-align: center;
}

.headlineSmall {
  opacity: 0;
}
.headlineLarge {
  color: var(--colour-cream-dark);
}

.copy {
  display: inline-block;
  position: fixed;
  bottom: 23vh;
  z-index: 4;
  display: flex;
  text-align: center;
  padding: 0 calc(var(--layout-padding) * 2);
  opacity: 0;
  pointer-events: none;
  touch-action: none;
}

.fade {
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.5) 67%,
    rgba(253, 187, 45, 0) 100%
  );
  height: 38vh;
  width: 100vw;
  position: fixed;
  bottom: 0;
  z-index: 3;
  opacity: 0;
}

.fade.light {
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 0.5) 67%,
    rgba(253, 187, 45, 0) 100%
  );
}

.navigationWrapper {
  align-items: center;
  position: fixed;
  left: 0;
  bottom: 0;
  right: 0;
  padding: var(--layout-gutter);
  display: flex;
  justify-content: space-around;
  z-index: 4;
  flex-direction: column;
  gap: 1.5rem;
  opacity: 0;
}

.navigationWrapper .buttons {
  display: flex;
  gap: 3rem;
  justify-content: center;
  align-items: center;
}

.navigationWrapper button {
  opacity: 0;
  scale: 0.7;
}

.navigationWrapper a {
  text-decoration: underline;
}

@media screen and (min-width: 500px) {
  .character {
    scale: 0.9;
    left: calc(50% * 0.9);
    transform: translateX(calc(-50%));
  }
  .copy {
    font-size: 4rem;
    line-height: 4.6rem;
  }
  .characterName {
    font-size: 14rem;
    line-height: 14.6rem;
    padding-inline: var(--layout-padding);
  }
  .headline {
    font-size: 3rem;
    line-height: 3.6rem;
  }
}
