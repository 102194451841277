/* Shapes */

.circle {
  position: relative;
  border-radius: 50%;
}

.rectangle {
  padding: 1.2rem 1.6rem;
  border: 1px solid var(--colour-black);
  box-shadow: 0px 2px 0px;
  border-radius: 4px;
}

/* Backgrounds */

.backgroundCreamDark {
  background-color: var(--colour-cream-dark);
}

.backgroundGreenLight {
  background-color: var(--colour-green-light);
}

.backgroundBlueLight {
  background-color: var(--colour-blue-light);
}

.backgroundRedLight {
  background-color: var(--colour-red-light);
}

/* Text */

.text {
  position: relative;
  z-index: 1;
}

.textCircle {
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.textUppercase {
  text-transform: uppercase;
}

/* Image */

.backgroundImage {
  position: relative;
  z-index: 0;
  width: 100%;
}
