.wrapper {
  background-color: var(--colour-cream);
  position: relative;
  overflow: hidden;
  margin-top: -50px;
  height: 100vh;
}

.header {
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding-top: 8rem;
  padding-inline: var(--layout-padding);
}

.qrContainer {
  position: absolute;
  bottom: 0;
  max-height: 80%;
  z-index: 1;
}

.qr {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  top: 0;
  right: 0;
  left: 0;
  transform: translate(15vw, -30px);
}

@media (min-width: 1100px) {
  .qr {
    transform: translateX(15vw);
  }
}

.qrCopy {
  width: 100%;
  font-size: 1.9rem;
  line-height: 2.1rem;
}

.qrImage {
  width: 15vw;
  margin-bottom: 15px;
}

.foreground {
  z-index: 1;
}

.cloudsWrapper {
  overflow: hidden;
}

.clouds {
  position: absolute;
  z-index: 0;
  top: 0;
  left: -50%;
  right: 50%;
  bottom: 0;
  background: url("./assets/clouds.png") repeat-x;
  width: 3976px;
  animation: slide 30s linear infinite;
  will-change: transform;
}

@keyframes slide {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-1988px);
  }
}
