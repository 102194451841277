.logo {
  align-items: baseline;
  display: flex;
  justify-content: center;
  padding-top: var(--layout-padding);
  transform-origin: top;
}

.logo svg {
  width: 50%;
}
/* [data-attr="letter"],
[data-attr="shadow"] {
  opacity: 1;
} */
