.itemsContainer {
  position: relative;
  touch-action: none;
  -webkit-user-select: none;
  user-select: none;
  touch-action: none;
  -webkit-user-select: none;
  user-select: none;
  cursor: grab;
  width: 100%;
  height: 100%;
}

.itemsContainer:active {
  cursor: grabbing;
}

.item {
  --width: 34;
  --ratio: 1.67;
  position: absolute;
  background: #f5f7f8;
  width: calc(var(--width) * var(--dvh));
  height: calc(var(--width) * var(--ratio) * var(--dvh));
  max-width: none;
  left: calc(50% - 0.5 * var(--width) * var(--dvh));
  top: calc(3.8 * var(--dvh));
  pointer-events: none;
  object-fit: cover;
  transform: translate3d(0, 0, 0);
  transform-origin: 50% 50%;
  isolation: isolate;
  contain: layout paint;
  box-shadow: 0 0 40px #fff;
  border-radius: 3vh;
  filter: blur(1.4px);
}

.item:nth-child(2n) {
  background: #eef2f5;
}

.item.itemCurrent {
  /* on tap hold shows mobile image menu */
  pointer-events: all;
  -webkit-touch-callout: default;
  touch-action: default;
  filter: none;
}
